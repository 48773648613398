import Lottie from "react-lottie";
import animation from './confirmation-nft.json'


export default function LottieAnimation() {
   const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animation,
      rendererSettings: {
         preserveAspectRatio: "xMidYMid slice",
      },
   };

   return (
      <div>
         <Lottie options={defaultOptions} height={300} width={300} />
      </div>
   );
}