import { GetNFTs, NFTProps } from "./GetNFTs";
import Cart, { CartData } from "./Cart"
import { useState, useEffect, FC } from "react";
import WalletContextProvider from "./Wallet";
import NFTViewer from "./NFTViewer";
import PKeySetter from "./PKeySetter";
import {
  WalletMultiButton
} from '@solana/wallet-adapter-react-ui';

import "./css/SelectionPage.css";
import './css/Wallet.css';




const SelectionPage: FC = () => {
  // const [nfts, setNfts] = useState([] as NFTProps[])
  const [cart, setCart] = useState([] as CartData[]);
  const [pkey, setPkey] = useState();
  // const [showShipping, setShowShipping] = useState(false);

  const updateCart = (nft_props: NFTProps[]) => {
    const cartedNFTS: CartData[] = nft_props.map(nft => (
      {
        nftData: nft,
        customer: pkey,

        size: {
          "small": {
            quantity: 0,
            price: 19
          },
          "large": {
            quantity: 0,
            price: 24
          }
        }
      })
    )

    // setNfts(nft_props);
    setCart(cartedNFTS);
  }

  useEffect(
    () => {
      GetNFTs(pkey).then(r => updateCart(r as NFTProps[]));
    }, [pkey])

  return (
    <WalletContextProvider>
      {!pkey
        ?
        <>
          <div className='wallet-button'>
            <WalletMultiButton className="wallet-connection-button-container" />
          </div>
          <PKeySetter setPkey={setPkey} />
        </>

        :
        <div>
          <div className="preview-container">
            <NFTViewer nfts={cart} setCart={setCart} />
            <Cart
              nfts={cart}
              setCart={setCart}
            />

          </div>
        </div>
      }
    </WalletContextProvider>

  )
}
export default SelectionPage;

