import { useCallback } from "react";

import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { Keypair, LAMPORTS_PER_SOL, SystemProgram, Transaction } from '@solana/web3.js';
import { createTransfer } from '@solana/pay';
import BigNumber from 'bignumber.js';

import { Box, Button } from "@mui/material";
import Icon from "@material-ui/core/Icon";

import mixpanel from 'mixpanel-browser';

import { MERCHANT_PKEY, MINT } from './constants';
import { createOrder, Order } from './Order';
import Countdown from './Countdown';


const USDCsvgIcon = (
	<Icon>
		<img alt="usdc" src="./usdc-logo.svg" />
	</Icon>
);

const SOLsvgIcon = (
	<Icon>
		<img alt="solana-pay" src="./solana-sol-logo.svg" />
	</Icon>
);

const PaymentHandler = (
	{
		usdc_total,
		sol_total,
		order,
		validForm,
		setPaymentStatus,
		setOrderId,
		getQuote
	}:
		{
			usdc_total: number,
			sol_total: number,
			order: Order,
			validForm: boolean,
			setPaymentStatus: Function,
			setOrderId: Function,
			getQuote: Function
		}) => {



	const { connection } = useConnection();
	const { publicKey, sendTransaction } = useWallet();

	const handleTransaction = useCallback(async (currency: string, total: number) => {
		let transaction;
		if (!publicKey) throw new WalletNotConnectedError();

		alert(process.env.REACT_APP_DEV_MODE);

		// this requires custom handling since SOL is a native currency
		if (currency === "SOL") {
			// in dev mode we don't want to be transferring large sums
			let lamports = !process.env.REACT_APP_DEV_MODE ? LAMPORTS_PER_SOL * total : 100;

			transaction = new Transaction().add(
				SystemProgram.transfer({
					fromPubkey: publicKey,
					toPubkey: MERCHANT_PKEY,
					lamports,
				})
			);
		}
		else {
			let amount = !process.env.REACT_APP_DEV_MODE ? new BigNumber(total) : new BigNumber(0.01)
			let recipient = MERCHANT_PKEY;
			let reference = new Keypair().publicKey;
			let memo = 'NFT Splash Payment';

			let splToken;
			try {
				splToken = !process.env.REACT_APP_DEV_MODE ? MINT[currency].mainnet : MINT[currency].devnet;
			} catch { throw new Error(`${currency} is not a supported currency`); }

			transaction = await createTransfer(connection, publicKey, { recipient, amount, splToken, reference, memo });
		}
		try {
			const signature = await sendTransaction(transaction, connection);
			const latestBlockHash = await connection.getLatestBlockhash();

			await connection.confirmTransaction({
				blockhash: latestBlockHash.blockhash,
				lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
				signature: signature,
			});
		} catch (error) {
			console.error(error);
			throw error;
		}
	}, [publicKey, sendTransaction, connection]);

	// given an order and currency process the transaction via the wallet and update the order accordingly
	const handlePayment = (currency: string, total: number) => {
		mixpanel.track("Payment Requested", {
			publicKey: publicKey!.toString(), total, currency
		})

		handleTransaction(currency, total).then(
			() => {
				console.log("Payment successful");
				let updatedOrder = order;
				updatedOrder.total = total;
				updatedOrder.currency = currency;
				updatedOrder.status = "paid";

				createOrder(updatedOrder).then(order_id => {
					setOrderId(order_id);
					setPaymentStatus("paid");

					mixpanel.track("Payment Successful", {
						publicKey: publicKey!.toString(),
						order_id, total, currency
					})
				})
			}).catch((e) => {
				setPaymentStatus("error");
				console.log("Error Occured with Payment")
				console.error(e);
				alert(e.message);
			});
	};

	return (
		<div className="payment-modal-buttons">
			<h2 className='pay-with'>Pay With</h2>
			<Button
				variant="contained"
				onClick={() => handlePayment("SOL", sol_total)}
				endIcon={SOLsvgIcon}
				disabled={!validForm}
				sx={{
					backgroundColor: "black",
					height: 48
				}}

			>{sol_total} SOL </Button>
			<div className='counter-container'>
				<div className='sol-update'>The price in SOL will update in: </div>
				<div className='counter-wrapper'>
					<Countdown getQuote={getQuote} />
				</div>

				<div className='sol-update' >seconds</div>
			</div>

			<div className='or'>OR</div>

			<Button
				sx={{
					backgroundColor: "#48435C",
					height: 48
				}}
				fullWidth
				onClick={() => handlePayment("USDC", usdc_total)}
				variant="contained"
				endIcon={USDCsvgIcon}
				disabled={!validForm}
			>{usdc_total} USDC</Button>
			<div className='filler'></div>
		</div >
	)
}

export default PaymentHandler;