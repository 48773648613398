import { NFTProps } from "./GetNFTs";
import CartPreview from "./CartPreview";
import { Box, Button, Modal } from "@mui/material";
import mixpanel from 'mixpanel-browser';
import { useState } from "react";
import AddressForm from "./AddressForm";
import PaymentModal from "./PaymentModal";
import ConfirmationModal from "./ConfirmationModal";
import "./css/Cart.css"



export interface SizedCart {
	quantity: number,
	price: number
}

export interface CartData {
	nftData: NFTProps,
	size: {
		[key: string]: SizedCart
	},
	customer?: string,
	image?: string
}

export interface AddressProps {
	name: string,
	email: string,
	address1: string,
	address2: string,
	city: string,
	state: string,
	zip: string,
	country: string,
	notes: string
}

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	overflow: 'auto',
	maxHeight: 600,
	width: 400,
	bgcolor: 'background.paper',
	border: '1px solid #000',
	boxShadow: 24,
	p: 4,
};

const Cart = ({ nfts, setCart }: { nfts: CartData[], setCart: Function }) => {

	// Handle modal
	const [open, setOpen] = useState(false);
	const [show_cart, setShowCart] = useState(false);

	const handleOpen = () => {
		setOpen(true);
		mixpanel.track('Checkout Clicked', {
			nfts: nfts.filter(c => c.size.small.quantity > 0 || c.size.large.quantity > 0)
		});
	};
	const handleClose = () => setOpen(false);

	const ToggleCart = () => setShowCart(!show_cart);

	// Address information
	const [name, setName] = useState("")
	const [email, setEmail] = useState("")
	const [address1, setAddress1] = useState("")
	const [address2, setAddress2] = useState("")
	const [city, setCity] = useState("")
	const [state, setState] = useState("")
	const [zip, setZip] = useState("")
	const [country, setCountry] = useState("")
	const [notes, setNotes] = useState("");

	const [validForm, setValidForm] = useState(false);

	// Payment information
	const [payment_status, setPaymentStatus] = useState("pending")
	const [order_id, setOrderId] = useState("");

	// Used to update quantity of nfts in cart
	const setNFT = (nft: CartData) => {
		let temp_nfts = [...nfts];
		for (let index = 0; index < temp_nfts.length; index++) {
			const element = temp_nfts[index];
			if (element.nftData.pkey === nft.nftData.pkey) {
				temp_nfts[index] = nft;
			}

		}
		setCart(temp_nfts)

	}

	let smallQuantity = nfts.map(nft => nft.size.small.quantity).reduce((smallQuantity, quantity) => { return smallQuantity + quantity }, 0)
	let largeQuantity = nfts.map(nft => nft.size.large.quantity).reduce((largeQuantity, quantity) => { return largeQuantity + quantity }, 0)


	return (
		(smallQuantity || largeQuantity)
			?
			<div className="cart-container">
				<div className="cart-receipt-container">
					<div className="receipt-container">
						<div className="receipt-total">
							<div className="receipt-total-label">
								Total:
							</div>
							<div className="receipt-total-value">
								{smallQuantity * 19 + largeQuantity * 24} USD
							</div>
						</div>

						<div className="receipt-total tax-shipping">
							<div className="receipt-total-label">
								Tax &amp; Shipping (5-7 business days)
							</div>
							<div className="receipt-total-value">
								Included
							</div>
						</div>

						<Box sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							gap: "20px",
							justifyContent: "center",
							height: "60px"
						}}>
							<Button
								onClick={ToggleCart}
								id="show-cart-button"
								size="large"
								variant="contained"
								fullWidth
								sx={{
									display: "none",
								}}
							>{`${show_cart ? "Hide" : "View"}`} Cart</Button>

							<Button
								id="checkout-button"
								onClick={() => handleOpen()}
								size="large"
								variant="contained"
								fullWidth

							>Checkout</Button>
						</Box>

					</div>
				</div>


				<div className={`${show_cart ? "" : "hide"}`}>
					<div className="cart-background1"></div>
					<div className="cart-background2"></div>
					<div className="cart-background-main">

						<div className="Cart">
							<div className="cart-previews-container">
								<h1 className="cart-title">Cart</h1>
								<hr />
								<div className="cart-previews">
									{nfts.filter(
										c => c.size.small.quantity > 0 || c.size.large.quantity > 0).map(nft =>
											<CartPreview key={nft.nftData.pkey} nft={nft} setNFT={setNFT} />)
									}
								</div>
							</div>

							<ConfirmationModal
								order_id={order_id}
								email={email}
								payment_status={payment_status}
							/>

							{payment_status !== "paid" && <Modal
								open={open}

								onClose={handleClose}
								aria-labelledby="modal-modal-title"
								aria-describedby="modal-modal-description"
							>
								<Box sx={style}>
									<div className="checkout-modal">
										<AddressForm
											name={name}
											setName={setName}
											email={email}
											setEmail={setEmail}
											address1={address1}
											setAddress1={setAddress1}
											address2={address2}
											setAddress2={setAddress2}
											city={city}
											setCity={setCity}
											state={state}
											setState={setState}
											zip={zip}
											setZip={setZip}
											country={country}
											setCountry={setCountry}
											notes={notes}
											setNotes={setNotes}
											setValidForm={setValidForm}
										/>
										<PaymentModal
											address={
												{
													name,
													email,
													address1,
													address2,
													city,
													state,
													zip,
													country,
													notes
												} as AddressProps
											}

											cart={
												nfts.filter(
													c => c.size.small.quantity > 0 || c.size.large.quantity > 0)
											}
											smallQuantity={smallQuantity}
											largeQuantity={largeQuantity}
											validForm={validForm}
											setPaymentStatus={setPaymentStatus}
											setOrderId={setOrderId}
										/>

									</div>
								</Box>
							</Modal>
							}




						</div >

					</div>
				</div ></div> : <div></div>

	)

}

export default Cart;