import { CartData } from "./Cart";
import NFTPrintBlock from "./NFTPrintBlock";
import PuchaseNFT from './PuchaseNFT'
import "./css/NFTViewer.css"

const NFTViewer = ({ nfts, setCart }: { nfts: CartData[], setCart: Function }) => {

	const setNFT = (nft: CartData) => {
		let temp_nfts = [...nfts];
		for (let index = 0; index < temp_nfts.length; index++) {
			const element = temp_nfts[index];
			if (element.nftData.pkey === nft.nftData.pkey) {
				temp_nfts[index] = nft;
			}

		}
		setCart(temp_nfts)

	}

	return (

		nfts.length > 0
			?
			<div className="NFTViewer">
				<div className="navbar">
					<a href="https://nftsplash.xyz">
						<div className="navbar-logo">
							<img src="./nft-splash-logo-3.svg" alt="logo" className="logo" />
						</div>
					</a>
				</div>
				<h1 className="selection-title">Select NFTs to Print</h1>
				{
					nfts.map(nft =>
						<NFTPrintBlock key={nft.nftData.pkey} nft={nft} setNFT={setNFT} />
					)
				}
			</div>
			: <PuchaseNFT />


	)
}

export default NFTViewer;