import React, { FC, ReactNode, useMemo } from 'react';
import { ConnectionProvider, WalletProvider, useWallet } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
	BitKeepWalletAdapter,
	BitpieWalletAdapter,
	BloctoWalletAdapter,
	CloverWalletAdapter,
	Coin98WalletAdapter,
	CoinhubWalletAdapter,
	ExodusWalletAdapter,
	GlowWalletAdapter,
	LedgerWalletAdapter,
	MathWalletAdapter,
	PhantomWalletAdapter,
	SafePalWalletAdapter,
	SlopeWalletAdapter,
	SolflareWalletAdapter,
	SolletWalletAdapter,
	SolongWalletAdapter,
	TokenPocketWalletAdapter,
	TorusWalletAdapter,

} from '@solana/wallet-adapter-wallets';

import {
	WalletModalProvider,
	WalletDisconnectButton,
	WalletMultiButton
} from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl } from '@solana/web3.js';
// import { GetNFTs } from './GetNFTs'
import PKeySetter from './PKeySetter'

import './css/Wallet.css';

// Default styles that can be overridden by your app
require('@solana/wallet-adapter-react-ui/styles.css');

// const Wallet = ({ setPkey }: { setPkey: Function }) => {
const WalletContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
	// The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
	const network = WalletAdapterNetwork.Devnet;

	// You can also provide a custom RPC endpoint.
	const endpoint = useMemo(() => clusterApiUrl(network), [network]);

	// @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
	// Only the wallets you configure here will be compiled into your application, and only the dependencies
	// of wallets that your users connect to will be loaded.
	const wallets = useMemo(
		() => [
			new BitKeepWalletAdapter(),
			new BitpieWalletAdapter(),
			new BloctoWalletAdapter(),
			new CloverWalletAdapter(),
			new Coin98WalletAdapter(),
			new CoinhubWalletAdapter(),
			new ExodusWalletAdapter(),
			new GlowWalletAdapter(),
			new LedgerWalletAdapter(),
			new MathWalletAdapter(),
			new PhantomWalletAdapter(),
			new SafePalWalletAdapter(),
			new SlopeWalletAdapter(),
			new SolflareWalletAdapter(),
			new SolletWalletAdapter(),
			new SolongWalletAdapter(),
			new TokenPocketWalletAdapter(),
			new TorusWalletAdapter(),
		],
		[]
	);

	return (
		<div>
			<ConnectionProvider endpoint={endpoint}>
				<WalletProvider wallets={wallets} autoConnect>
					<WalletModalProvider>{children}</WalletModalProvider>
				</WalletProvider>
			</ConnectionProvider>
		</div>
	);
};
export default WalletContextProvider;
