import { Box, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { LANDING_PAGE_URL } from "./constants";
import './css/ConfirmationModal.css';
import LottieAnimation from './LottieAnimation'


const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	overflow: 'auto',
	maxHeight: 600,
	width: 400,
	height: 400,
	bgcolor: 'background.paper',
	border: '1px solid #000',
	boxShadow: 24,
	p: 4,
};

const ConfirmationModal = ({ order_id, email, payment_status }: { order_id: string, email: string, payment_status: string }) => {
	// Handle modal
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => {
		setOpen(false);
		window.location.assign(LANDING_PAGE_URL);
	};

	// TODO: set payment status back to pending or something


	useEffect(() => {
		if (payment_status === "paid") {
			handleOpen();
		}
	}, [payment_status]);

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style} className="shipping-modal">
				<div className="order-received fadeInUp">
					<h1>Order Received</h1>
					<div className="order-number">
						Your order number is #{order_id}
					</div>
				</div>
				<div className="confirmation-animation">
					<LottieAnimation></LottieAnimation>
				</div>
				<div className="email-confirmation fadeInDown">
					<div className="confirmation-text">A confirmation email has been sent to <span style={{ fontWeight: 500 }}>{email}</span></div>
					<div className="confirmation-text">Your order will ship in 5-7 business days</div>
					<div className="confirmation-text">contact@nftsplash.xyz</div>
				</div>

			</Box>
		</Modal >
	);
}


export default ConfirmationModal;