import React from 'react';
import ReactDOM from 'react-dom';
import SelectionPage from './SelectionPage';
import reportWebVitals from './reportWebVitals';
import Certificate from './Certificate';

import mixpanel from 'mixpanel-browser';
// or with require() syntax:
// const mixpanel = require('mixpanel-browser');          

// Enabling the debug mode flag is useful during implementation,
// but it's recommended you remove it for production
mixpanel.init('70430c8cb070f9f9b9b2552a185735c0', { debug: true });
mixpanel.track('Checkout Page');

ReactDOM.render(
  <React.StrictMode>
    <SelectionPage />
  </React.StrictMode>,
  document.getElementById('root')
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
