import { CartData } from "./Cart";
import QuantityGroupedButtons from "./QuantityGroupedButtons";
import "./css/CartPreview.css"

const CartPreview = ({ nft, setNFT }: { nft: CartData, setNFT: Function }) => {

	const setCounterSmall = (count: number) => {
		let temp_nft = nft;
		temp_nft.size.small.quantity = count;
		setNFT(temp_nft)
	}

	const setCounterLarge = (count: number) => {
		let temp_nft = nft;
		temp_nft.size.large.quantity = count;
		setNFT(temp_nft)
	}



	return (
		<div>
			{
				(nft.size.small.quantity > 0 || nft.size.large.quantity > 0) &&
				<div className="cart-preview">
					<div className="nft-name">{nft.nftData.name}</div>
					<div className="quantity-label">Quantity</div>
					<div className="cart-print-container">
						<div className="cart-mini-print print_space">
							<img src={nft.image} alt={nft.nftData.name} />
						</div>
					</div>
					<div className="cart-modifiers">
						<div className="cart-large-modifer">
							<div className="cart-print-size">17" x 22"</div>
							<div className="cart-quantity-price">
								<QuantityGroupedButtons size="small" counter={nft.size.large.quantity} setCounter={setCounterLarge} />
								{nft.size.large.price * nft.size.large.quantity > 0 &&
									<div className="cart-total">
										{nft.size.large.price * nft.size.large.quantity} USD
									</div>
								}
							</div>
						</div>
						<div className="cart-small-modifer">
							<div className="cart-print-size">8.5" x 11"</div>
							<div className="cart-quantity-price">
								<QuantityGroupedButtons size="small" counter={nft.size.small.quantity} setCounter={setCounterSmall} />
								{nft.size.small.price * nft.size.small.quantity > 0 &&
									<div className="cart-total">
										{nft.size.small.price * nft.size.small.quantity} USD
									</div>
								}
							</div>
						</div>

					</div>
				</div>
			}
			<hr />
		</div>
	)
}

export default CartPreview;