import { AddressProps, CartData } from './Cart';
import { SERVER_URL } from './constants';

export interface Order {
	purchaser_pkey: string;
	cart: CartData[];
	address: AddressProps;
	total: number;
	date: Date;
	currency: string;
	status: string;
}

export async function createOrder(order: Order) {

	const order_id = await fetch(`${SERVER_URL}/order/new`,
		{
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*'
			},

			body: JSON.stringify(order)
		},
	)
		.then(res => res.json())
		.then(res => res.confirmation_id)
		.catch(err => console.error(err));

	return order_id;
}
