import { CountdownCircleTimer } from 'react-countdown-circle-timer'


const Countdown = ({ getQuote }: { getQuote: Function }) => (
	<CountdownCircleTimer
		isPlaying
		duration={60}
		colors={['#49d400', '#004777', '#F7B801', '#A30000', '#A30000']}
		colorsTime={[60, 35, 20, 10, 0]}
		size={30}
		strokeWidth={2}
		onComplete={() => {
			// do your stuff here
			getQuote();
			return { shouldRepeat: true, delay: 1 } // repeat animation in 1.5 seconds
		}}
	>
		{({ remainingTime }) => remainingTime}

	</CountdownCircleTimer>
)
export default Countdown;