import { Divider } from "@material-ui/core";
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from "@mui/material";
import './css/PurchaseNFT.css'

export interface NFTMarketPlaceElement {
	name: string,
	logo?: string,
	url: string,

}

const NFTMarketPlaces: NFTMarketPlaceElement[] = [
	{
		name: "OpenSea",
		logo: "https://storage.googleapis.com/opensea-static/Logomark/Logomark-Blue.png",
		url: "https://opensea.io/"
	},
	{
		name: "Magic Eden",
		logo: "https://media-thumbs.golden.com/J4RHXaoS_Jdn4BZFSdbjcgw6haI=/200x200/smart/golden-storage-production.s3.amazonaws.com%2Ftopic_images%2F9d92079d1ad14d4fa46f91e3f5c0f89f.png",
		url: "https://magiceden.io/"
	},
	{
		name: "SolSea",
		logo: "https://solsea.io/assets/new_solsea_logo.svg",
		url: "https://solsea.io/"
	},
	{
		name: "Metaplex",
		logo: "https://avatars.githubusercontent.com/u/84874526?s=280&v=4",
		url: "https://www.metaplex.com/"
	},
	{
		name: "Solanart",
		logo: "https://solanart.io/solanart-logo.svg",
		url: "https://solanart.io/"
	},

	{
		name: "DigitalEyes",
		logo: "https://digitaleyes.market/favicon.ico",
		url: "https://digitaleyes.market/"
	},
	{
		name: "Solport",
		logo: "https://solport.io/img/logo.d360a5e4.svg",
		url: "https://solport.io/"
	},
	{
		name: "Alpha Art",
		logo: "https://dashboard-assets.dappradar.com/document/12299/alphaart-dapp-marketplaces-solana-logo-166x166_3a5f10ada3b54ecd691aa525597757d7.png",
		url: "https://alpha.art/"
	}

]


const PurchaseNFT = () => {
	return (
		<div className="nft-marketplaces-container">

			<Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', padding: "20px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
				<List subheader={
					<ListSubheader sx={{ fontSize: "25px", lineHeight: "30px", marginBottom: "15px" }} component="div" id="list-subheader">
						<div style={{ textAlign: "center" }}>Uh Oh 😰</div>
						<div style={{ marginTop: "15px" }}>Seems like you don't own any NFTs</div>
						<div style={{ fontSize: "18px", marginTop: "15px" }}>We suggest you checkout one of these popular marketplaces:</div>
					</ListSubheader>}>

					{NFTMarketPlaces.map(
						nft_marketplace => (
							<div key={nft_marketplace.name}>
								<Divider />
								<ListItem>
									<ListItemButton target="_blank" href={nft_marketplace.url}>
										<ListItemIcon sx={{ width: '40px', height: '40px' }}>
											<img alt={nft_marketplace.name} src={nft_marketplace.logo} />
										</ListItemIcon>
										<ListItemText primary={nft_marketplace.name} />
									</ListItemButton>
								</ListItem>

							</div>
						)
					)}
				</List>
			</Box >
		</div >
	);
}

export default PurchaseNFT;