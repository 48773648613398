import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { useState } from "react";
import "./css/QuantityGroupedButtons.css"


const QuantityGroupedButtons = ({ counter, setCounter, size }: { counter: number, setCounter: Function, size: "small" | "medium" | "large" | undefined }) => {
	return (
		<ButtonGroup className="QuantityGroupedButtons" size="small" aria-label="small outlined button group">
			{counter > 0 && <Button size={size} onClick={() => setCounter(counter - 1)}>-</Button>}
			{counter > 0 && <Button size={size} >{counter}</Button>}
			<Button size={size} onClick={() => setCounter(counter + 1)}>+</Button>
		</ButtonGroup>
	);

}

export default QuantityGroupedButtons;