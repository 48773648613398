import { Box, Button, FormControl, FormGroup, Input, InputLabel, TextField } from '@material-ui/core';
import { margin } from '@mui/system';
import React, { useEffect, useState } from 'react';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import './css/AddressForm.css';


const AddressForm = (
	{
		name,
		email,
		address1,
		address2,
		city,
		state,
		zip,
		country,
		notes,
		setName,
		setEmail,
		setAddress1,
		setAddress2,
		setCity,
		setState,
		setZip,
		setCountry,
		setNotes,
		setValidForm
	}:
		{
			name: string,
			email: string,
			address1: string,
			address2: string,
			city: string,
			state: string,
			zip: string,
			country: string,
			notes: string,
			setName: Function,
			setEmail: Function,
			setAddress1: Function,
			setAddress2: Function,
			setCity: Function,
			setState: Function,
			setZip: Function,
			setCountry: Function,
			setNotes: Function,
			setValidForm: Function
		}

) => {
	const [value, setValue] = useState(null);

	const setFullAddressState = (addresses: google.maps.GeocoderAddressComponent[]) => {
		let street_number = addresses.find(item => item.types.indexOf("street_number") >= 0)
		let route = addresses.find(item => item.types.indexOf("route") >= 0)


		if (street_number && route) {
			setAddress1(street_number.long_name.concat(" ", route.long_name));
		}


		addresses.map(
			(address) => {
				if (address.types.indexOf("locality") >= 0 || address.types.indexOf("postal_town") >= 0)
					setCity(address.long_name)
				else if (address.types.indexOf("administrative_area_level_1") >= 0)
					setState(address.long_name)
				else if (address.types.indexOf("country") >= 0)
					setCountry(address.long_name)
				else if (address.types.indexOf("postal_code") >= 0)
					setZip(address.long_name)
				return null
			}
		)

	}

	const setFullAddress = (val: any) => {
		if (val) {
			geocodeByPlaceId(val.value.place_id)
				.then(response => setFullAddressState(response[0].address_components))
				.catch(error => console.error(error));
		}
	}

	const validEmail = (email: string) => {
		return email.match(
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		) ? true : false;
	}


	useEffect(() => {
		setFullAddress(value);
	}
		, [value]
	)

	const validateAddressForm = (
		name: string,
		email: string,
		address1: string,
		city: string,
		zip: string,
		country: string) => {
		if (email && address1 && city && zip && country) {

			if (validEmail(email)) {
				setValidForm(true);
			}
		}
		else {
			setValidForm(false);
		}
	}

	useEffect(() => {
		validateAddressForm(name,
			email,
			address1,
			city,
			zip,
			country);
	}
		, [
			name,
			email,
			address1,
			city,
			zip,
			country]
	)


	return (
		<div className="AddressForm" >
			<h2 className="shipping-title">Shipping</h2>

			<Box sx={{
				mt: "20px"
			}}>
				<FormGroup>
					<FormControl>
						<InputLabel className="input-label" htmlFor="purchaser-name">Full Name</InputLabel>
						<Input id="purchaser-name" aria-describedby="purchaser-name" value={name} onChange={(e) => setName(e.target.value)} required />
					</FormControl>

					<TextField
						label="Email"
						InputLabelProps={{ className: "input-label" }}
						error={!validEmail(email)}
						value={email}
						helperText={!validEmail(email) ? "Please enter a valid email" : ""}
						onChange={(e) => setEmail(e.target.value)}
					/>

					{/* <FormControl>
						<InputLabel className="input-label" htmlFor="purchaser-email">Email</InputLabel>
						<Input
							error={!validEmail(email)}
							id="purchaser-email" type="email" aria-describedby="purchaser-email" value={email} onChange={(e) => setEmail(e.target.value)} required />
					</FormControl> */}

					<GooglePlacesAutocomplete
						selectProps={{
							value,
							onChange: setValue,
							placeholder: "Enter Shipping Address"
						}}
					/>

					{value && (
						<>
							<FormControl>
								<InputLabel className="input-label" htmlFor="address-line-1">Address</InputLabel>
								<Input id="address-line-1" aria-describedby="address-line-1" value={address1} onChange={(e) => setAddress1(e.target.value)} required />
							</FormControl>

							<FormControl>
								<InputLabel className="input-label" htmlFor="address-line-2">Apartment, unit, suite, or floor #</InputLabel>
								<Input placeholder="optional" id="address-line-2" aria-describedby="address-line-2" value={address2} onChange={(e) => setAddress2(e.target.value)} />
							</FormControl>

							<FormControl>
								<InputLabel className="input-label" htmlFor="city">City</InputLabel>
								<Input id="city" aria-describedby="city" value={city} onChange={(e) => setCity(e.target.value)} required />
							</FormControl>

							<FormControl>
								<InputLabel className="input-label" htmlFor="state">State/Province</InputLabel>
								<Input id="state" aria-describedby="state" value={state} onChange={(e) => setState(e.target.value)} required />
							</FormControl>

							<FormControl>
								<InputLabel className="input-label" htmlFor="zip">Postal Code</InputLabel>
								<Input id="zip" aria-describedby="zip" value={zip} onChange={(e) => setZip(e.target.value)} required />
							</FormControl>

							<FormControl>
								<InputLabel className="input-label" htmlFor="country">Region/Country</InputLabel>
								<Input id="country" aria-describedby="country" value={country} onChange={(e) => setCountry(e.target.value)} required />
							</FormControl>

							<TextField
								label="Special Notes"
								InputLabelProps={{ className: "input-label" }}
								multiline
								minRows={4}
								value={notes}
								helperText="optional"
								onChange={(e) => setNotes(e.target.value)}
							/>
						</>
					)}
				</FormGroup>
			</Box>

		</div >
	);
}

export default AddressForm;