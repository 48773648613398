import { useWallet } from '@solana/wallet-adapter-react';
import mixpanel from 'mixpanel-browser';

const PKeySetter = ({ setPkey }: { setPkey: Function }) => {
	const { publicKey } = useWallet();

	if (publicKey) {
		setPkey(publicKey);
	}
	return (<div></div>);
}

export default PKeySetter;