import { PublicKey } from "@solana/web3.js"

export const MERCHANT_PKEY = new PublicKey('8xWF7fUFMVCYct4cqD9qxpSifpLQPhXFeW9JAoajPcNv')
export const USDC_MINT = new PublicKey('4zMMC9srt5Ri5X14GAgXhaHii3GnPAEERYPJgZJDncDU');
export const SERVER_URL = "https://api.nftsplash.xyz";
export const LANDING_PAGE_URL = "https://nftsplash.xyz";

export interface mint_config {
	[key: string]: {
		devnet: PublicKey,
		mainnet: PublicKey,
	}
}
export const MINT: mint_config= {
	"USDC": {
		"devnet": new PublicKey('EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v'),
		// the mainnet mint is not real
		"mainnet":new PublicKey('6VWPp8pNmFdD46LupaCe7CfCg9eWQwcUYUkq2suvJbuK')
	}
}