import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, FormControlLabel, FormGroup, Checkbox, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { AddressProps, CartData } from "./Cart";
import { LANDING_PAGE_URL, SERVER_URL } from "./constants";
import { Order } from "./Order";
import { useWallet } from '@solana/wallet-adapter-react';
import PaymentHandler from "./PaymentHandler";
import './css/PaymentModal.css';




async function getCurrentSOLQuote() {

	const solQuote = await fetch(`${SERVER_URL}/quote/sol`)
		.then(response => response.json())
		.then(r => r.quote)
		.catch(error => console.log('error', error));

	return solQuote;
}


const PaymentModal = (
	{
		address,
		cart,
		smallQuantity,
		largeQuantity,
		validForm,
		setPaymentStatus,
		setOrderId
	}:
		{
			address: AddressProps,
			cart: CartData[],
			smallQuantity: number,
			largeQuantity: number,
			validForm: boolean,
			setPaymentStatus: Function,
			setOrderId: Function
		}) => {

	const [solPrice, setSolPrice] = useState(0);
	const [tos_checked, setTosChecked] = useState(false);
	const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTosChecked(event.target.checked);
	};

	const total = smallQuantity * 19 + largeQuantity * 24;

	const order: Order = {
		purchaser_pkey: useWallet().publicKey!.toString(),
		cart: cart,
		address: address,
		total: 0,
		date: new Date(),
		currency: "",
		status: "pending"
	}


	const getQuote = (amount: number = total) => {
		getCurrentSOLQuote().then(
			quote => {
				const price_in_sol = amount / (quote);
				setSolPrice(Math.ceil(price_in_sol * 100) / 100)
			}).catch(err => console.log(err));
	}

	useEffect(() => {
		getQuote(total);
	}, [total]);


	return (
		<div className="payment-modal" >

			<div className="payment-container">

				<div className="payment-summary">

					<h2>Payment</h2>

					<div>
						{/* add new cart preview component here */}

					</div>




					<TableContainer component={Paper}>
						<Table aria-label="receipt table">
							<TableBody>
								{
									smallQuantity > 0
									&&
									<>
										<TableRow
											sx={{
												bgcolor: "#B7ACB9"
											}}>

											<TableCell>
												<div className="table-title">8.5" x 11" Art Print </div>
											</TableCell>

											<TableCell align="center">
												<div className="table-title">Quantity</div>
											</TableCell>

											<TableCell align="right">
												<div className="table-title">Total</div>
											</TableCell>

										</TableRow>


										{cart.filter(nft => nft.size.small.quantity > 0).map(nft => (
											< TableRow >
												<TableCell>
													<div className="table-content">
														<a href={nft.image} target="_blank" rel="noreferrer">{nft.nftData.name}</a>
													</div>
												</TableCell>

												<TableCell align="center">
													<div className="table-content">{nft.size.small.quantity}</div>
												</TableCell>


												<TableCell align="right"><div className="table-content">
													{nft.size.small.quantity * 19} USD
												</div></TableCell>
											</TableRow>
										))}
									</>
								}
								{
									largeQuantity > 0
									&&
									<>
										<TableRow
											sx={{
												bgcolor: "#B7ACB9",
											}}>

											<TableCell>
												<div className="table-title">17" x 22" Art Print</div>
											</TableCell>

											<TableCell align="center">
												<div className="table-title">Quantity</div>
											</TableCell>

											<TableCell align="right">
												<div className="table-title">Total</div>
											</TableCell>

										</TableRow>

										{cart.filter(nft => nft.size.large.quantity > 0).map(nft => (
											< TableRow >
												<TableCell><div className="table-content"><a href={nft.image} target="_blank" rel="noreferrer">{nft.nftData.name}</a></div></TableCell>
												<TableCell align="center"><div className="table-content">{nft.size.large.quantity}</div></TableCell>
												<TableCell align="right"><div className="table-content">{nft.size.large.quantity * 24} USD</div></TableCell>
											</TableRow>
										))}
									</>
								}

							</TableBody>
						</Table>
					</TableContainer>
					<div>
						<div className="receipt-total tax-shipping">
							<div className="receipt-total-label">
								Tax &amp; Shipping (5-7 business days)
							</div>
							<div className="receipt-total-value">
								Included
							</div>
						</div>
						<div className="receipt-total payment-total">
							<div className="receipt-total-label">
								Grand Total:
							</div>
							<div className="receipt-total-value">
								{total} USD
							</div>
						</div>
					</div>
					<FormGroup
						sx={{ pt: "1rem" }}
					>
						<div style={{ width: 420, fontSize: 13, fontWeight: 400, lineHeight: 1.5 }}>I understand NFT Splash's <a href={`${LANDING_PAGE_URL}/refundpolicy.html`}>refund policy</a> in addition to the <a href={`${LANDING_PAGE_URL}/tos.html`}>Terms of Service</a>. I own the necessary digital rights to print the selected NFTs.</div>

						<FormControlLabel sx={{ pt: "1rem", pl: "0.5rem" }} control={<Checkbox sx={{ alignItems: "start" }} checked={tos_checked} onChange={handleCheckChange} />} label={
							<Typography
								sx={{ fontSize: 15, pl: "0.5rem" }}>
								I acknowledge the statement above
							</Typography>


						} />

					</FormGroup>

				</div>

				{/* for size and color: https://mui.com/material-ui/react-checkbox/#size */}

				<PaymentHandler
					usdc_total={total}
					sol_total={solPrice}
					order={order}
					validForm={validForm && tos_checked}
					setPaymentStatus={setPaymentStatus}
					setOrderId={setOrderId}
					getQuote={getQuote}
				/>
			</div>
		</div >
	)
}

export default PaymentModal;