import {
	Connection,
	PublicKey
} from "@solana/web3.js";

import {
	Metadata,
	MetadataData,
} from "@metaplex-foundation/mpl-token-metadata";


export type NFTFilter = { uri: string } | { image: string };

export interface NFTProps {
	name: string;
	nft: NFTFilter;
	pkey: string;
}

export async function GetNFTs(pubkey?: PublicKey) {
	// if (!pubkey) return;

	// DEV
	// pubkey = new PublicKey('4EofPVY7voKY6H58GBh9gmngQHVbBe1dXMMNF873Yx2E');
	pubkey = new PublicKey('4eXYY8XJLkHXZsJ7UZB5HReEenfDrQmaJG2cngKK9xoX');

	console.log("pubkey-> " + pubkey);

	let conn = new Connection("https://api.mainnet-beta.solana.com");


	const nfts = await Metadata.findDataByOwner(conn, pubkey).then(r => r);


	const nft_props: NFTProps[] = (
		nfts as MetadataData[]
	).map(
		(nft) => (
			{
				pkey: nft.mint,
				name: nft.data.name,
				nft: { uri: nft.data.uri }
			}
		)
	)

	return nft_props;
}
