import { useEffect } from "react";
import { CartData } from "./Cart"
import { NFTFilter } from "./GetNFTs";
import { SERVER_URL } from "./constants";
import QuantityGroupedButtons from "./QuantityGroupedButtons";
import "./css/NFTPrintBlock.css"

const NFTPrintBlock = ({ nft, setNFT }: { nft: CartData, setNFT: Function }) => {

	const assignImage = (img: string) => {
		let temp_nft = nft;
		temp_nft.image = img;
		setNFT(temp_nft)

	}


	async function getImageFromUri(nft_filter: NFTFilter) {
		if (nft.image) {
			return;
		}
		if ("image" in nft_filter) {
			let image_ = nft_filter.image as string;
			if (!(image_).endsWith("gif")) {
				assignImage(image_);
			}
			return;
		}

		const uri = `${SERVER_URL}/image?uri=${nft_filter.uri}`
		let res = await fetch(uri).then(response => response.json()).catch(err => { console.log("error fetching image: " + err) });

		if (res) {
			if ("image" in res) {
				let image_ = res.image as string;
				if (!(image_).endsWith("gif")) {
					assignImage(image_);
				}
				return;
			}
		}
	}

	const setCounterSmall = (count: number) => {
		let temp_nft = nft;
		temp_nft.size.small.quantity = count;
		setNFT(temp_nft)
	}

	const setCounterLarge = (count: number) => {
		let temp_nft = nft;
		temp_nft.size.large.quantity = count;
		setNFT(temp_nft)
	}

	useEffect(() => {
		getImageFromUri(nft.nftData.nft);
	}, [nft]);

	return (
		<div>
			{
				nft.image &&
				<div>
					<div className="NFTPrintBlock">

						<div className="half-split-large 17x22">
							<div className="print-container">
								<div className="large-print print_space">
									<img src={nft.image} alt={nft.nftData.name} />
								</div>
								<div className="print-size">17" x 22"</div>
								<QuantityGroupedButtons size="large" counter={nft.size.large.quantity} setCounter={setCounterLarge} />
							</div>

						</div>

						<div className="half-split-small 8x11">
							<div className="nft-name-container">
								<div className="nft-name-display">{nft.nftData.name}</div>
							</div>
							<div className="print-container">
								<div className="small-print print_space">
									<img src={nft.image} alt={nft.nftData.name} />
								</div>
								<div className="print-size">8.5" x 11"</div>
								<QuantityGroupedButtons size="large" counter={nft.size.small.quantity} setCounter={setCounterSmall} />
							</div>
						</div>
					</div>
				</div>

			}
		</div>

	)


}

export default NFTPrintBlock;